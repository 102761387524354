.reactDatePicker {
    height: 45px;
    width: 290px;
    border: 0.5px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
}

.MuiAutocomplete-inputRoot {
    flex-wrap: nowrap !important;
    height: 45px;
    width: 300px;
}